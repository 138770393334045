function IconAwesomeLock(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.5 36" {...props}>
			<path
				fill="currentColor"
				d="M28.125 15.75h-1.687v-5.062a10.688 10.688 0 0 0-21.375 0v5.062H3.375A3.376 3.376 0 0 0 0 19.125v13.5A3.376 3.376 0 0 0 3.375 36h24.75a3.376 3.376 0 0 0 3.375-3.375v-13.5a3.376 3.376 0 0 0-3.375-3.375zm-7.312 0H10.688v-5.062a5.063 5.063 0 0 1 10.125 0z"
			/>
		</svg>
	);
}

export default IconAwesomeLock;

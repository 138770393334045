function IconSunEmpty(props) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="#D9E1EE"
			xmlns="http://www.w3.org/2000/svg"
			focusable="false"
			data-testid="icon-sun-empty"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2.47009C11.4477 2.47009 11 2.91781 11 3.47009V5.52619C11 6.07847 11.4477 6.52619 12 6.52619C12.5523 6.52619 13 6.07847 13 5.52619V3.47009C13 2.91781 12.5523 2.47009 12 2.47009ZM19.6043 6.12488C19.2567 5.69567 18.627 5.62949 18.1978 5.97705L16.5999 7.27099C16.1707 7.61856 16.1046 8.24825 16.4521 8.67746C16.7997 9.10667 17.4294 9.17285 17.8586 8.82528L19.4565 7.53134C19.8857 7.18378 19.9519 6.55408 19.6043 6.12488ZM20.7437 13.1811C21.2818 13.3053 21.6174 13.8423 21.4931 14.3804C21.3689 14.9186 20.8319 15.2541 20.2938 15.1298L18.2904 14.6673C17.7523 14.5431 17.4167 14.0061 17.541 13.468C17.6652 12.9299 18.2022 12.5943 18.7403 12.7186L20.7437 13.1811ZM16.2344 20.9781C16.7308 20.736 16.937 20.1373 16.6949 19.6409L15.7935 17.7929C15.5514 17.2965 14.9528 17.0904 14.4564 17.3325C13.96 17.5746 13.7538 18.1733 13.9959 18.6697L14.8973 20.5177C15.1394 21.0141 15.7381 21.2202 16.2344 20.9781ZM9.10274 20.5177C8.86063 21.0141 8.26196 21.2202 7.76557 20.9781C7.26918 20.736 7.06305 20.1373 7.30515 19.6409L8.20648 17.7929C8.44859 17.2965 9.04726 17.0904 9.54365 17.3325C10.04 17.5746 10.2462 18.1733 10.0041 18.6697L9.10274 20.5177ZM2.5069 14.3804C2.63114 14.9186 3.16809 15.2541 3.70622 15.1298L5.70962 14.6673C6.24775 14.5431 6.58327 14.0061 6.45904 13.468C6.3348 12.9299 5.79785 12.5943 5.25972 12.7186L3.25632 13.1811C2.71819 13.3053 2.38266 13.8423 2.5069 14.3804ZM4.54355 7.53134C4.11434 7.18378 4.04816 6.55408 4.39572 6.12488C4.74329 5.69567 5.37298 5.62949 5.80219 5.97705L7.40007 7.27099C7.82928 7.61856 7.89546 8.24825 7.5479 8.67746C7.20034 9.10667 6.57064 9.17285 6.14143 8.82528L4.54355 7.53134ZM8.00001 12C8.00001 14.2089 9.79112 16 12 16C14.2096 16 16 14.2089 16 12C16 9.79114 14.2089 8.00002 12 8.00002C9.79037 8.00002 8.00001 9.79114 8.00001 12Z"
				fill="#D9E1EE"
			/>
		</svg>
	);
}

export default IconSunEmpty;

import { memo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { sendTagOnChangeLanguage } from "app/utils/analytics";
import { replaceShopInUrl } from "app/utils/urlDataExtractor";
import AdvancedSelect from "app/pages/.shared/form/AdvancedSelect";

const LanguageSwitcher = ({ shops = {}, currentShop }) => {
	const selectLanguage = useCallback(
		({ value }) => {
			const selectedShop = value;
			const redirectURL = replaceShopInUrl(window.location.href, currentShop, selectedShop);
			window.location = redirectURL;
			sendTagOnChangeLanguage(selectedShop.slice(0, 2));
		},
		[currentShop]
	);
	const options = Object.keys(shops).map(shop => {
		return { value: shops[shop].code, label: shops[shop].label };
	});

	return (
		<AdvancedSelect
			placeholder={<FormattedMessage id="select.language.placeholder" />}
			options={options}
			value={options.find(({ value }) => {
				return value === currentShop;
			})}
			isSearchable={false}
			getOptionValue={({ value }) => value}
			getOptionLabel={({ label }) => label}
			onChange={selectLanguage}
		/>
	);
};

LanguageSwitcher.propTypes = {
	shops: PropTypes.object,
	currentShop: PropTypes.string,
};

export default memo(LanguageSwitcher);

import { LOADING_BAR_STATUS } from "app/constants";
import { LOADING_BAR_FINISHED, LOADING_BAR_SHOW } from "./loadingBarActionTypes";

export default function loadingBarReducer(status = LOADING_BAR_STATUS.HIDDEN, action) {
	switch (action.type) {
		case LOADING_BAR_FINISHED:
			return LOADING_BAR_STATUS.FINISHED;
		case LOADING_BAR_SHOW:
			return LOADING_BAR_STATUS.LOADING;
		default:
			return status;
	}
}

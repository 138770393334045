import { useContext, useEffect } from "react";
import { authSuccess, logoutUser } from "app/pages/Auth/AuthActions";
import { parseQueries } from "app/utils/routerUtils";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateStrategy } from "app/reducers/partnerActionCreators";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import AppGlobalsContext from "app/AppGlobalsContext";
import { COOKIES_AUTH_EXPIRES } from "app/constants";

const OperatorRedirect = ({ updateStrategy, authSuccess, logoutUser }) => {
	const { search } = useLocation();
	const { shop } = useContext(AppGlobalsContext);
	const query = parseQueries(search);

	const { uuid, email, token, partner, redirectUrl, strategy } = query;

	useEffect(() => {
		// Pour les brands ayant une stack membre, on authentifie le user
		if (uuid && email && token && partner) {
			logoutUser();
			authSuccess({
				data: {
					uuid,
					email,
					token,
					partner: { code: partner },
				},
			});
		} else {
			Cookies.set("authToken", token, {
				expires: COOKIES_AUTH_EXPIRES,
				path: `/${shop}`,
			});
		}

		if (strategy) {
			updateStrategy(strategy);
		}
	}, [uuid, email, token, partner, strategy]);

	const pathname = redirectUrl && !redirectUrl.includes("://") ? redirectUrl : "/listing";

	const [redirectUrlPathname, redirectUrlSearch] = pathname.split("?");

	return (
		<Navigate
			to={{ pathname: redirectUrlPathname, search: redirectUrlSearch }}
			replace={true}
		/>
	);
};

OperatorRedirect.propTypes = {
	updateStrategy: PropTypes.func,
	authSuccess: PropTypes.func,
	logoutUser: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ updateStrategy, authSuccess, logoutUser }, dispatch);
};

export default connect(
	null,
	mapDispatchToProps
)(OperatorRedirect);

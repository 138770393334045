import { ACCEPT_MAP_POLICY } from "../../../actionTypes";

const initialState = false;

export default function mapPolicyReducer(state = initialState, action) {
	switch (action.type) {
		case ACCEPT_MAP_POLICY:
			return true;
		default:
			return state;
	}
}

import PropTypes from "prop-types";
import { htmlSanitize } from "app/utils/sanitize";
import "./paragraphs.scss";

const Paragraphs = ({ paragraphs = [], enableHTMLFormatting = false }) => {
	if (Array.isArray(paragraphs)) {
		const paragraphNodes = paragraphs.map((paragraph, index) => {
			if (enableHTMLFormatting === true) {
				return (
					<article
						className="paragraphs__item"
						key={index}
						dangerouslySetInnerHTML={{ __html: htmlSanitize(paragraph) }}
					/>
				);
			}
			return (
				<article className="paragraphs__item" key={index}>
					{paragraph}
				</article>
			);
		});
		return <section className="paragraphs">{paragraphNodes}</section>;
	}
	return (
		<section
			className="paragraphs"
			dangerouslySetInnerHTML={{ __html: htmlSanitize(paragraphs) }}
		/>
	);
};

Paragraphs.propTypes = {
	paragraphs: PropTypes.arrayOf(PropTypes.string),
	enableHTMLFormatting: PropTypes.bool,
};

export default Paragraphs;

import { inRange } from "lodash/number";

const SVA_VARIANTS = [
	{
		minIncluded: 800,
		maxExcluded: 806,
		textColor: "#6DC13A",
		bgColor: "#77B52A",
		freeFirstLine: "help.desk.service.and.call.free.first.part",
		secondLine: "help.desk.service.and.call.free.second.part",
	},
	{
		minIncluded: 806,
		maxExcluded: 810,
		textColor: "#868B9A",
		bgColor: "#91919A",
		freeFirstLine: "help.desk.service.free",
		secondLine: "help.desk.call.not.free",
	},
	{
		minIncluded: 810,
		maxExcluded: 830,
		textColor: "#A04282",
		bgColor: "#A64079",
		secondLine: "help.desk.call.not.free",
	},
	{
		minIncluded: 890,
		maxExcluded: 900,
		textColor: "#A04282",
		bgColor: "#A64079",
		secondLine: "help.desk.call.not.free",
	},
];

export const getSvaVariant = firstFourDigits =>
	SVA_VARIANTS.find(variant =>
		inRange(Number(firstFourDigits), variant.minIncluded, variant.maxExcluded)
	);

import { connect } from "react-redux";
import HelpDeskPhoneNumber from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumber";
import withRouter from "app/utils/hocs/withRouter";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { getSvaVariant } from "app/pages/.shared/HelpDeskPhoneNumber/PhoneNumberSVA/phoneNumberSvaUtils";

const mapStateToProps = (state, ownProps) => {
	const pathname = ownProps.location.pathname;
	const isPagePayment =
		pathname.includes("/booking/payment") || pathname.includes("/booking/restoreSession");
	const isQuotationPage = pathname.includes("/booking/quote");
	const isConfirmationPage = pathname.includes("/booking/confirmation");
	const contextCallCenter = getContextCallCenter({
		isPaymentPage: isPagePayment,
		isQuotationPage,
		isConfirmationPage,
	})(state);

	if (ownProps.useSVA && contextCallCenter.displayPhone) {
		const firstFourDisplayPhoneDigits = contextCallCenter.displayPhone.join("").slice(0, 4);
		const svaVariant = getSvaVariant(firstFourDisplayPhoneDigits);
		return {
			svaVariant,
			...contextCallCenter,
		};
	}

	return {
		...contextCallCenter,
	};
};

export default withRouter(connect(mapStateToProps)(HelpDeskPhoneNumber));

import PropTypes from "prop-types";
import { useContext } from "react";
import appGlobalsContext from "app/AppGlobalsContext";

const Loader = ({ width, height }) => {
	const { publicPath = "" } = useContext(appGlobalsContext);
	return (
		<img
			className="loader-spinner"
			style={{ height, width }}
			src={`${publicPath}/icons/loader.gif`}
			alt="loader"
		/>
	);
};

Loader.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Loader.defaultProps = {
	width: 100,
	height: 100,
};

export default Loader;

import { useParams, Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const NavigateWithParams = ({ to, ...rest }) => {
	const params = useParams();
	const { search } = useLocation();

	let pathNameRedirect = typeof to === "function" ? to(params) : to;
	if (params["*"]?.length > 0) {
		pathNameRedirect += `/${params["*"]}`;
	}

	const toValue = {
		pathname: pathNameRedirect,
	};

	if (search) {
		toValue.search = search;
	}

	return <Navigate to={toValue} {...rest} />;
};

NavigateWithParams.propTypes = {
	to: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default NavigateWithParams;

import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconAccount from "app/pages/.shared/IconAccount/IconAccount";
import IconMenu from "app/pages/.shared/static/icons/IconMenu";
import { isAuthenticated } from "app/pages/Auth/authSelectors";
import { brandPropTypes, resolutionPropType } from "app/utils/propTypes";
import { BRANDS_WITH_ACCESS_TO_MY_BOOKING, RESOLUTION } from "app/constants";
import { FormattedMessage } from "react-intl";
import "./HamburgerAccount.scss";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const HamburgerAccount = ({ resolution, onToogle, userIsConnected = false, brand }) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const showMenuLabel = BRANDS_WITH_ACCESS_TO_MY_BOOKING.includes(brand) || isMobile;
	let hamburgerLabel = "";
	if (showMenuLabel) {
		hamburgerLabel = "header.nav.item.menu";
	} else if (userIsConnected) {
		hamburgerLabel = "header.nav.item.my.account";
	} else {
		hamburgerLabel = "header.login";
	}
	return (
		<div className="hamburger-account" data-testid="hamburger-account" onClick={onToogle}>
			{isMobile || showMenuLabel ? <IconMenu /> : <IconAccount />}

			<div className="hamburger-account__label">
				<Typography variant={TYPOGRAPHY_VARIANTS.SMALL} isBold>
					<FormattedMessage id={hamburgerLabel} />
				</Typography>
			</div>
		</div>
	);
};

HamburgerAccount.propTypes = {
	userIsConnected: PropTypes.bool,
	resolution: resolutionPropType,
	onToogle: PropTypes.func,
	brand: brandPropTypes,
};

const mapStateToProps = state => ({
	userIsConnected: isAuthenticated(state),
	resolution: state.resolution,
	brand: state.brand.code,
});

export default connect(mapStateToProps)(HamburgerAccount);

function IconRecommendation(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			data-testid="icon-recommendation"
			{...props}
		>
			<path
				d="M12.7424 9.2069H18.2396C20.0414 9.2069 21.3415 10.8747 20.9203 12.5509L19.8893 17.8958C19.6336 19.6812 18.0008 21 16.1659 21H4.76071C3.73925 21 3 20.2624 3 19.2414V12C3 10.979 3.73925 10.2414 4.76071 10.2414H6.35755L9.27981 3.43825C9.39403 3.17234 9.65556 3 9.94488 3C11.5884 3 12.7424 4.15137 12.7424 5.7931V9.2069Z"
				fill="#fff"
			/>
		</svg>
	);
}

export default IconRecommendation;

const IconCircleClose = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		{...props}
	>
		<g clipPath="url(#clip0_2331_21182)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 2.17921C10.0576 2.17921 8.15888 2.75519 6.54386 3.83431C4.92883 4.91343 3.67008 6.44723 2.92676 8.24175C2.18345 10.0363 1.98897 12.0109 2.3679 13.9159C2.74684 15.821 3.68218 17.5709 5.05565 18.9444C6.42911 20.3178 8.17901 21.2532 10.0841 21.6321C11.9891 22.011 13.9637 21.8166 15.7583 21.0732C17.5528 20.3299 19.0866 19.0712 20.1657 17.4561C21.2448 15.8411 21.8208 13.9424 21.8208 12C21.8208 10.7103 21.5668 9.43326 21.0732 8.24175C20.5797 7.05023 19.8563 5.9676 18.9444 5.05565C18.0324 4.14371 16.9498 3.42031 15.7583 2.92677C14.5667 2.43323 13.2897 2.17921 12 2.17921ZM0 12C0 9.62663 0.703788 7.30655 2.02236 5.33316C3.34094 3.35977 5.21509 1.8217 7.4078 0.913451C9.60051 0.00519945 12.0133 -0.232441 14.3411 0.230582C16.6689 0.693605 18.8071 1.83649 20.4853 3.51472C22.1635 5.19295 23.3064 7.33115 23.7694 9.65892C24.2324 11.9867 23.9948 14.3995 23.0866 16.5922C22.1783 18.7849 20.6402 20.6591 18.6668 21.9776C16.6935 23.2962 14.3734 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12Z"
				fill="currentColor"
			/>
			<path
				d="M7.2002 16.8L16.8002 7.20001"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.2002 7.20001L16.8002 16.8"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2331_21182">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default IconCircleClose;

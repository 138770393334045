import {
	LOADING_BAR_FINISHED,
	LOADING_BAR_SHOW,
} from "app/pages/.shared/LoadingBar/loadingBarActionTypes";

export const showLoadingBar = () => ({
	type: LOADING_BAR_SHOW,
});

export const finishLoadingBar = () => {
	return dispatch => {
		dispatch({
			type: LOADING_BAR_FINISHED,
		});
	};
};

import { memo } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import Button from "app/pages/.shared/form/Button";
import RadioInput from "app/pages/.shared/form/RadioInput";
import { NEWSLETTER_FREQUENCIES } from "app/constants";
import { FormattedMessage } from "react-intl";
import Separator from "app/pages/.shared/Separator";
import "./NewsLetterFrequencyForm.scss";

const NewsLetterFrequencyForm = ({ onSubmit }) => {
	return (
		<div className="newsletter-frequency-form">
			<div className="newsletter-frequency-form__header">
				<FormattedMessage id="newsletter.frequency.form.modal.title">
					{message => <div className="newsletter-frequency-form__title">{message}</div>}
				</FormattedMessage>
				<Separator design="middle" />
				<FormattedMessage id="newsletter.frequency.form.modal.intro">
					{message => <div className="newsletter-frequency-form__intro">{message}</div>}
				</FormattedMessage>
			</div>
			<Formik
				enableReinitialize
				initialValues={{
					newsletterPreference: {
						frequency: NEWSLETTER_FREQUENCIES.ALL,
						remarketing: true,
					},
				}}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={onSubmit}
			>
				{({ isSubmitting, setFieldValue, submitForm }) => {
					const handleOnNoneSelect = () => {
						setFieldValue(
							"newsletterPreference.frequency",
							NEWSLETTER_FREQUENCIES.NONE
						);

						setFieldValue("newsletterPreference.remarketing", false);

						submitForm();
					};

					return (
						<Form className="newsletter-frequency-form__form">
							<RadioInput
								name="newsletterPreference.frequency"
								id={NEWSLETTER_FREQUENCIES.ALL}
								value={NEWSLETTER_FREQUENCIES.ALL}
								showIcon={true}
							>
								<div className="newsletter-frequency-form__input">
									<FormattedMessage id="newsletter.frequency.form.modal.recommended">
										{message => (
											<div className="newsletter-frequency-form__label">
												{message}
											</div>
										)}
									</FormattedMessage>
									<FormattedMessage id="newsletter.frequency.form.modal.all.label">
										{message => (
											<div className="newsletter-frequency-form__sublabel">
												{message}
											</div>
										)}
									</FormattedMessage>
								</div>
							</RadioInput>
							<RadioInput
								name="newsletterPreference.frequency"
								id={NEWSLETTER_FREQUENCIES.WEEKLY}
								value={NEWSLETTER_FREQUENCIES.WEEKLY}
								showIcon={true}
							>
								<div className="newsletter-frequency-form__input">
									<FormattedMessage id="newsletter.frequency.form.modal.basic">
										{message => (
											<div className="newsletter-frequency-form__label">
												{message}
											</div>
										)}
									</FormattedMessage>
									<FormattedMessage id="newsletter.frequency.form.modal.weekly.label">
										{message => (
											<div className="newsletter-frequency-form__sublabel">
												{message}
											</div>
										)}
									</FormattedMessage>
								</div>
							</RadioInput>
							<div className="newsletter-frequency-form__button">
								<Button
									variant="primary"
									submit
									loading={isSubmitting}
									data-testid="save-button"
								>
									<FormattedMessage id="newsletter.frequency.form.modal.save.button" />
								</Button>
							</div>
							<FormattedMessage id="newsletter.frequency.form.modal.none.label">
								{message => (
									<div
										className="newsletter-frequency-form__link"
										onClick={handleOnNoneSelect}
									>
										{message}
									</div>
								)}
							</FormattedMessage>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

NewsLetterFrequencyForm.propTypes = {
	onSubmit: PropTypes.func,
};

export default memo(NewsLetterFrequencyForm);

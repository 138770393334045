import { memo } from "react";
import PropTypes from "prop-types";
import "./IconAccount.scss";
import DotNotification from "app/pages/.shared/DotNotification/DotNotification";

const IconAccount = ({ userIsConnected }) => {
	return (
		<div className="icon-account__wpr">
			{userIsConnected && <DotNotification />}
			<svg
				width="29"
				height="30"
				viewBox="0 0 29 30"
				fill="none"
				className="icon-account__icon"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M14.7134 0.764706C6.90103 0.764706 0.571289 7.13615 0.571289 15C0.571289 22.8639 6.90103 29.2353 14.7134 29.2353C22.5258 29.2353 28.8555 22.8639 28.8555 15C28.8555 7.13615 22.5258 0.764706 14.7134 0.764706ZM14.7134 6.27514C17.4848 6.27514 19.7316 8.53672 19.7316 11.3264C19.7316 14.116 17.4848 16.3776 14.7134 16.3776C11.942 16.3776 9.69524 14.116 9.69524 11.3264C9.69524 8.53672 11.942 6.27514 14.7134 6.27514ZM14.7134 26.0209C11.3661 26.0209 8.36657 24.494 6.3593 22.1062C7.43136 20.0742 9.52987 18.6736 11.9762 18.6736C12.1131 18.6736 12.2499 18.6966 12.3811 18.7368C13.1224 18.9778 13.898 19.1328 14.7134 19.1328C15.5289 19.1328 16.3101 18.9778 17.0457 18.7368C17.1769 18.6966 17.3137 18.6736 17.4506 18.6736C19.897 18.6736 21.9955 20.0742 23.0675 22.1062C21.0603 24.494 18.0608 26.0209 14.7134 26.0209Z"
					fill="currentColor"
				/>
			</svg>
		</div>
	);
};

IconAccount.propTypes = {
	userIsConnected: PropTypes.bool,
};

export default memo(IconAccount);
